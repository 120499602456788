import React, { Component, Fragment } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Header from './components/Header';
import Modal from './components/Modal';
import HubPerformance from './components/performance/HubPerformance';
// import Overview from './components/production/Overview';
import QueueScreen from './components/queueScreen/QueueScreen';
import LiveDispatch from './components/liveDispatch/LiveDispatch';
import PickMode from './components/pickMode/PickMode';
import Warmer from './components/warmer/Warmer';
import DispatchScan from './components/dispatch-scan/DispatchScan';
import Inventory from './components/inventory/Inventory';
import LiveInventoryAdjustment from './components/inventory/LiveInventoryAdjustment';
import LiveInventory from './components/inventory/LiveInventory';
import InventoryAdjustment from './components/inventory/InventoryAdjustment';
import InventoryAdjustmentUpdate from './components/inventory/InventoryAdjustmentUpdate';
import InventoryStockCount from './components/inventory/InventoryStockCount';
import InventoryStockCountShiftMeals from './components/inventory/InventoryStockCountShiftMeals';
import InventoryStockCountUpdate from './components/inventory/InventoryStockCountUpdate';
import InventoryReceiveStock from './components/inventory/InventoryReceiveStock';
import InventoryReceiveStockUpdate from './components/inventory/InventoryReceiveStockUpdate';
import InventoryReceiveStockUpdateAddons from './components/inventory/InventoryReceiveStockUpdateAddons';
import InventoryReceiveStockOdoo from './components/inventory/InventoryReceiveStockUpdateOdoo';
import CashierPickup from './components/cashierPickup/CashierPickup';
import OrdersKitchen from './components/ordersKitchen/OrdersKitchen';
import Login from './components/Login';
import { inject, observer } from 'mobx-react';
import SVGSymbols from './components/SVGIcons';
import Cookies from 'js-cookie';
import qs from 'qs';
import InventoryStockCountShiftAddons from './components/inventory/InventoryStockCountShiftAddons';
import InventoryComponentStockUpdate from './components/inventory/InventoryComponentStockUpdate';
import InventoryAddComponentStock from './components/inventory/InventoryAddComponentStock';
import LiveInventoryComponent from './components/inventory/LiveInventoryComponent';
import InventoryStockCountShiftComponents from './components/inventory/InventoryStockCountShiftComponents';
import InventoryComponentStockCountUpdate from './components/inventory/InventoryComponentStockCountUpdate';
import AttendanceScan from './components/outletPartnerAttendance/AttendanceScan';
import BagScanning from "./components/bagScan/BagScanning";
import AllOrders from "./components/cashierPickup/AllOrders";
import PrintStockCount from "./components/inventory/PrintStockCount";
import CookingV2 from './components/cookingV2/CookingV2';
import InstaService from './components/instaService/InstaService';

@inject('UserStore')
@observer
class App extends Component {
    state = {
        loading: true,
    };

    componentDidMount() {
        const { UserStore } = this.props;
        const windowUrl = window.location.href;
        const parameters = windowUrl ? qs.parse(windowUrl.split('?')[1]) : {};
        const { token, hub: hubCode } = parameters;
        if (token && hubCode) {
            UserStore.authedUser = token;
        } else {
            if (Cookies.get('token')) {
                UserStore.authedUser = Cookies.get('token');
            }
        }

        this.setState({ loading: false });

        UserStore.checkPrinterStatus();
    }

    closeModal = () => {
        const {
            UserStore: { setMessage },
        } = this.props;
        setMessage('');
    };

    render() {
        const {
            UserStore: { authedUser, message, currentPage },
        } = this.props;
        if (this.state.loading) {
            return <div />;
        }

        return (
            <div className={`App ${currentPage}__page`}>
                <Router>
                    <Fragment>
                        <SVGSymbols />
                        {authedUser && <Header />}
                        {message && (
                            <Modal onBackdropClicked={this.closeModal}>
                                {message}
                            </Modal>
                        )}
                        {!authedUser ? (
                            <Route exact path="/" component={Login} />
                        ) : (
                            <Switch>
                                <Route exact path={'/'} component={Inventory} />
                                <Route
                                    exact
                                    path={'/production'}
                                >
                                    <Redirect to="/cooking" />
                                </Route>

                                <Route
                                    exact
                                    path={'/cashier-pickup'}
                                    component={CashierPickup}
                                />
                                <Route
                                    exact
                                    path={'/cooking-old'}
                                    component={OrdersKitchen}
                                />
                                <Route
                                    exact
                                    path="/hub-performance"
                                    component={HubPerformance}
                                />
                                <Route
                                    exact
                                    path="/packing"
                                    component={LiveDispatch}
                                />
                                <Route
                                    exact
                                    path="/dispatch-scan"
                                    component={DispatchScan}
                                />
                                <Route
                                    exact
                                    path="/inventory"
                                    component={Inventory}
                                />
                                <Route
                                    exact
                                    path="/inventory/live/adjustment"
                                    component={LiveInventoryAdjustment}
                                />
                                <Route
                                    exact
                                    path="/inventory/live"
                                    component={LiveInventory}
                                />
                                <Route
                                    exact
                                    path="/inventory/live/adjustment/component"
                                    component={LiveInventoryComponent}
                                />
                                <Route
                                    exact
                                    path="/inventory/adjustment"
                                    component={InventoryAdjustment}
                                />
                                <Route
                                    exact
                                    path="/inventory/adjustment/:type(addons|meals)"
                                    component={InventoryAdjustmentUpdate}
                                />
                                <Route
                                    exact
                                    path="/inventory/adjustment/component"
                                    component={InventoryComponentStockUpdate}
                                />
                                <Route
                                    exact
                                    path="/inventory/stock-count"
                                    component={InventoryStockCount}
                                />
                                <Route
                                    exact
                                    path="/inventory/stock-count/meals"
                                    component={InventoryStockCountShiftMeals}
                                />
                                <Route
                                    exact
                                    path="/inventory/stock-count/meals/:shift"
                                    component={InventoryStockCountUpdate}
                                />
                                <Route
                                    exact
                                    path="/inventory/stock-count/addons"
                                    component={InventoryStockCountShiftAddons}
                                />
                                <Route
                                    exact
                                    path="/inventory/stock-count/addons/:shift"
                                    component={InventoryStockCountUpdate}
                                />
                                <Route
                                    exact
                                    path="/inventory/stock-count/components"
                                    component={InventoryStockCountShiftComponents}
                                />
                                <Route
                                    exact
                                    path="/inventory/stock-count/components/:shift"
                                    component={InventoryComponentStockCountUpdate}
                                />
                                <Route
                                    exact
                                    path="/inventory/receive-stock"
                                    component={InventoryReceiveStock}
                                />
                                <Route
                                    exact
                                    path="/inventory/receive-stock/:itemType(addons|meals)"
                                    component={InventoryReceiveStockUpdate}
                                />
                                <Route
                                    exact
                                    path="/inventory/receive-stock/addons-supplier"
                                    component={
                                        InventoryReceiveStockUpdateAddons
                                    }
                                />
                                <Route
                                    exact
                                    path="/inventory/receive-stock/odoo"
                                    component={
                                        InventoryReceiveStockOdoo
                                    }
                                />
                                <Route
                                    exact
                                    path="/inventory/add-component-stock"
                                    component={InventoryAddComponentStock}
                                />
                                <Route
                                    path="/queue-screen"
                                    component={QueueScreen}
                                />
                                <Route
                                    exact
                                    path="/pick-mode"
                                    component={PickMode}
                                />
                                <Route
                                    exact
                                    path="/warmer"
                                    component={Warmer}
                                />
                                <Route
                                    exact
                                    path="/attendance-scan"
                                    component={AttendanceScan}
                                />
                                <Route
                                    exact
                                    path="/bag-scanning"
                                    component={BagScanning}
                                />
                                <Route
                                    exact
                                    path={'/all-orders'}
                                    component={AllOrders}
                                />
                                <Route
                                    exact
                                    path="/inventory/print-stock-count"
                                    component={PrintStockCount}
                                />
                                <Route 
                                    exact 
                                    path="/live-dispatch">
                                    <Redirect to="/packing" />
                                </Route>
                                <Route 
                                    exact 
                                    path="/orders-kitchen">
                                    <Redirect to="/cooking" />
                                </Route>
                                <Route
                                    exact
                                    path="/cooking"
                                    component={CookingV2}
                                />
                                <Route
                                    exact
                                    path="/insta-service"
                                    component={InstaService}
                                />
                            </Switch>
                        )}
                    </Fragment>
                </Router>
            </div>
        );
    }
}

export default App;
