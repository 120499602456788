import React from 'react';
import SalePlatformIcon from './SalePlatformIcon';

const base = 'orders-kitchen';

export default function InCartOrderSkuCard({ item, subItem }) {
    return (
        <div className={`${base}__sku_card row col-xs-12`} style={{padding: 0}}>
            <div className={`${base}__card_order_mode col-xs-1`}>
                <SalePlatformIcon
                    key={item.menu_item_id}
                    order={item}
                />
            </div>
            <div className={`${base}__card_sku col-xs-8`} style={{paddingLeft: '30px'}}>
                {subItem ? subItem.sku.length > 13 ?
                    subItem.sku.substring(0, 10) + '...' :
                    subItem.sku :
                    item.sku.length > 13 ?
                    item.sku.substring(0, 10) + '...' :
                    item.sku }
            </div>
            <div className={`${base}__card_quantity_cont col-xs-1`} style={{padding: 0}}>
                <span className={`${base}__quantity_x`}>x</span>
                <span className={`${base}__quantity`}>
                    {(subItem && item.family_combo) ? subItem.quantity : item.quantity}
                </span>
            </div>
        </div>
    );
}