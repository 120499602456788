import React, { Component } from 'react';
import DineInScannerView from './DineInScannerView';
import TakeawayScannerView from './TakeawayScannerView';
import IdleScannerView from './IdleScannerView';
import WarmerScannerView from './WarmerScannerView';
import BagScannerView from './BagScannerView';
import BagScannerLiveDispatchView from './BagScannerLiveDispatchView';
import BarcodeReader from 'react-barcode-reader';
import Loading from '../Loading';
import InstaserviceScannerView from './InstaserviceScannerView';
import QrReader from 'react-qr-reader';

const base = 'dispatch_scan';

const DINE_IN = 'DINE_IN';
const ON_ROUTE = 'ON_ROUTE';
const TAKE_AWAY = 'TAKE_AWAY';
const IN_WARMER = 'IN_WARMER';
const PRINTING_FAILED = 'Printing Failed';

class ScanOverview extends Component {
    handleError = (err) => {
        console.error(err);
    };

    render() {
        const {
            scannedItem,
            scannedItemSKU,
            scannedItemError,
            handleScan,
            loading,
            itemImage,
            originalItemImageLink,
            show,
        } = this.props;
        const printingStatus = 'Printing sticker..';
        if (loading) {
            return <Loading />;
        }

        let salePlatform = null;
        if (scannedItem != null) {
            if (scannedItem.item) {
                salePlatform = scannedItem.item.sale_platform;
            } else {
                salePlatform = scannedItem.sale_platform;
            }
        }

        if(!salePlatform){
            salePlatform = 'PopMeals'
        }
        if (!salePlatform.includes('GrabFood')
            && !salePlatform.includes('FoodPanda')
            && !salePlatform.includes('ShopeeFood')){
            salePlatform = 'PopMeals'
        }

        let brandName = 'PopMeals';
        if(salePlatform.includes('GrabFood')) {
            if (salePlatform.indexOf(' - GrabFood') > -1){
                brandName = salePlatform.split(' - GrabFood')[0];
            }
        } else if(salePlatform.includes('FoodPanda')) {
            if (salePlatform.indexOf(' - FoodPanda') > -1){
                brandName = salePlatform.split(' - FoodPanda')[0];
            }
        } else if (salePlatform.includes('ShopeeFood')){
            if (salePlatform.indexOf(' - ShopeeFood') > -1){
                brandName = salePlatform.split(' - ShopeeFood')[0];
            }
        }

        return (
            <div className={`${base}__container`}
                style={(scannedItem && scannedItem.isWarmerItem) ? {backgroundColor : "#131415"} : 
                brandName == "PopMeals" ? {backgroundColor: "#FEC700", height: '100%'} : {backgroundColor: "#4154FF", height: '100%'}}>
                <div className={`${base}__header display--inline-block`}
                    style={(scannedItem && scannedItem.isWarmerItem) ? {display: 'none'} : {}}>
                    {brandName == "PopMeals" ? (
                        <img
                            src="https://image.popcontent.link/Pop.svg"
                            style={{height: '4rem', marginBottom: '0.25rem'}}
                        />
                        ) : (
                            <span style={{textTransform: 'uppercase'}}>{brandName}</span>
                        )}
                </div>

                {!scannedItem && (
                    <div className={`bag_scan__qr-reader`}>
                            <div className={`bag_scan__scan-text`}> Scan the bag's QR code </div>
                            <QrReader
                                delay={500}
                                onError={this.handleError}
                                onScan={handleScan}
                                facingMode="environment"
                            />
                    </div>
                )}

                <BarcodeReader onError={this.handleError} onScan={handleScan} />
                {scannedItem &&
                scannedItem.isWarmerItem &&
                scannedItemError.length === 0 &&
                show ? (
                    <InstaserviceScannerView
                        scannedItem={scannedItem}
                        scannedItemSKU={scannedItemSKU}
                        originalItemImageLink={originalItemImageLink}
                        itemImage={itemImage}
                    />
                ) : scannedItem &&
                scannedItem.pick_up_type === DINE_IN &&
                scannedItemError.length === 0 &&
                show ? (
                    <DineInScannerView
                        scannedItem={scannedItem}
                        scannedItemSKU={scannedItemSKU}
                        originalItemImageLink={originalItemImageLink}
                        itemImage={itemImage}
                    />
                ) : scannedItem &&
                scannedItem.pick_up_type === TAKE_AWAY &&
                scannedItemError.length === 0 &&
                show ? (
                    <TakeawayScannerView
                        scannedItem={scannedItem}
                        scannedItemSKU={scannedItemSKU}
                        originalItemImageLink={originalItemImageLink}
                        itemImage={itemImage}
                    />
                ) : scannedItem &&
                scannedItem.item &&
                scannedItem.item.status === ON_ROUTE &&
                printingStatus !== PRINTING_FAILED &&
                scannedItemError.length === 0 &&
                show ? (
                    <BagScannerView
                        scannedItem={scannedItem}
                        scannedItemSKU={scannedItemSKU}
                        originalItemImageLink={originalItemImageLink}
                        itemImage={itemImage}
                    />
                ) : scannedItem &&
                scannedItem.item &&
                scannedItem.item.status === IN_WARMER &&
                scannedItemError.length === 0 &&
                show ? (
                    <WarmerScannerView
                        scannedItemSKU={scannedItemSKU}
                        originalItemImageLink={originalItemImageLink}
                        itemImage={itemImage}
                    />
                ) : scannedItem &&
                    scannedItem.bag &&
                    show ? (
                    <BagScannerLiveDispatchView
                        scannedItem={scannedItem}
                        itemImage={itemImage}
                    />
                ) : (
                    <IdleScannerView scannedItemError={scannedItemError} />
                )}
            </div>
        );
    }
}

export default ScanOverview;
