import React, { Component } from 'react';
import moment from 'moment';

const base = 'queue-screen';
const MAXIMUM_CHARACTERS = 9;
const ONE_SECOND = 1000;
const ONE_MIN = ONE_SECOND * 60;
const ORDER_PREPARATION_MINS = 10;

class OrderCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            order_eta: this.calculate_eta(props.order),
        };
    }

    componentDidMount() {
        this.setCountdown();
    }

    componentDidUpdate = (prevProps) => {
        const { order } = this.props;
        if (order.order_number !== prevProps.order.order_number) {
            this.resetCountdown();
            this.setCountdown();
        }
    };

    componentWillUnmount() {
        this.resetCountdown();
    }

    resetCountdown() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    setCountdown() {
        const { order } = this.props;
        this.interval = setInterval(() => {
            this.setState({ order_eta: this.calculate_eta(order) });
        }, 5 * ONE_SECOND); // 5 seconds
    }

    calculate_eta = (order) => {
        let eta_in_ms;
        if (order.ready_for_pickup_at) {
            //pickup order, eta to be calculated using ready_for_pickup_at (future point in time at first)
            eta_in_ms = order.ready_for_pickup_at;
        } else {
            //dine in order, eta to be calculated using order_date (past point in time)
            eta_in_ms = moment(order.order_date)
                .add(ORDER_PREPARATION_MINS, 'minute')
                .valueOf();
        }
        const eta_in_min = parseInt(
            Math.floor((eta_in_ms - Date.now()) / ONE_MIN)
        );
        return eta_in_min > 1 ? eta_in_min + ' mins' : '1 min';
    };

    render() {
        const { order_eta } = this.state;
        const { order, showOnModal } = this.props;
        const customerOriginalName = order.customer_name || order.order_number;
        const customerName =
            customerOriginalName.length > MAXIMUM_CHARACTERS
                ? customerOriginalName.slice(0, MAXIMUM_CHARACTERS - 2) + '..'
                : customerOriginalName;

        return (
            <div
                className={`${base}__order-card bg--white ${
                    showOnModal ? `${base}__order-card-modal` : 'ms'
                }`}
            >
                <p
                    className={`pickup-number mts ${
                        showOnModal ? 'pickup-number-modal' : ''
                    }`}
                >
                    -{' '}
                    {order.order_date
                        ? (order.table_number != null ? order.table_number : order.pick_up_number)
                        : order.order_number}{' '}
                    -
                </p>
                <h4
                    className={`customer-name ${
                        showOnModal ? 'customer-name-modal' : ''
                    }`}
                >
                    {customerName}
                </h4>
                <svg
                    className={order.transaction_status === "Pending" ? `lower-payment-status-svg ${
                        showOnModal ? 'lower-svg-modal' : ''
                    }` : `lower-svg ${
                        showOnModal ? 'lower-svg-modal' : ''
                    }`}
                    width="107"
                    height="47"
                    viewBox="0 0 107 47"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                {order.transaction_status === "Pending" && (
                    <circle cx="53.5" cy="100.5" r="100.5" fill="#F4594F" />
                )}
                {order.transaction_status === "Complete" && (
                    <circle cx="53.5" cy="53.5" r="53.5" fill="#7056B5" />
                )}
                </svg>
                {order.status && (
                    <p
                        className={`order-eta-status mtl ${
                            showOnModal ? 'order-eta-status-modal' : ''
                        }`}
                        style={order.transaction_status === "Pending" ? {textTransform: "uppercase"} : {textTransform: "lowercase"}}
                    >
                        {order.status === 'Prepared' || showOnModal || order.status === 'Completed'
                            ? 'Enjoy!'
                            : `${order_eta}`}
                    </p>
                )}
            </div>
        );
    }
}

export default OrderCard;
