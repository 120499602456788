import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import Modal from '../Modal';
import moment from 'moment';
import ReactLoading from 'react-loading';
import { invert } from 'lodash';

const audio = new Audio(`${process.env.PUBLIC_URL}/alarm.mp3`);

const base = 'live-dispatch';

@inject('LiveDispatchStore')
@observer
class Route extends Component {
    state = {
        hours: '0',
        minutes: '0',
        seconds: '00',
        delay: false,
        orderHours: '0',
        orderMinutes: '0',
        orderSeconds: '00',
        orderDelay: false,
        showPartyRiderModal: false,
        showPartyRiderError: true,
        showConfirmationModal: false,
        loading: false,
    };

    componentDidMount = () => {
        this.setCountdown();
    };

    setCountdown = () => {
        const { route } = this.props;
        const startTime = moment(route.start_date, 'x');
        const originalOrderTime = route.original_order_date
            ? moment(route.original_order_date, 'x')
            : null;
        let now = moment();
        let delay = false;
        let orderDelay = false;

        if (now.isAfter(moment(startTime, 'x'))) {
            delay = true;
        }

        if (originalOrderTime && now.isAfter(moment(originalOrderTime, 'x'))) {
            orderDelay = true;
        }

        this.setState({ delay, orderDelay }, () => {
            this.interval = setInterval(this.countdown, 1000);
        });
    };

    countdown = () => {
        const { delay, orderDelay } = this.state;
        const { route } = this.props;
        const startTime = moment(route.start_date, 'x');
        const originalOrderTime = moment(route.original_order_date, 'x');
        let now, then, orderNow, orderThen;

        if (delay) {
            now = moment(startTime, 'x');
            then = moment();
        } else {
            now = moment();
            then = moment(startTime, 'x');
        }

        if (orderDelay) {
            orderNow = moment(originalOrderTime, 'x');
            orderThen = moment();
        } else {
            orderNow = moment();
            orderThen = moment(originalOrderTime, 'x');
        }

        const duration = moment.duration(then.diff(now)).asMilliseconds();
        const orderDuration = moment
            .duration(orderThen.diff(orderNow))
            .asMilliseconds();

        const hours = moment.utc(duration).format('H');
        const minutes = moment.utc(duration).format('m');
        const seconds = moment.utc(duration).format('ss');
        const orderHours = moment.utc(orderDuration).format('H');
        const orderMinutes = moment.utc(orderDuration).format('m');
        const orderSeconds = moment.utc(orderDuration).format('ss');

        if (!delay && minutes === '0' && seconds === '00') {
            this.setState({ hours, minutes, seconds, delay: true });
        } else {
            this.setState({ hours, minutes, seconds });
        }
        if (!orderDelay && orderMinutes === '0' && orderSeconds === '00') {
            this.setState({
                orderHours,
                orderMinutes,
                orderSeconds,
                orderDelay: true,
            });
        } else {
            this.setState({ orderHours, orderMinutes, orderSeconds });
        }
    };

    resetCountdown = () => {
        if (this.interval) {
            clearInterval(this.interval);
            this.setState(
                {
                    hours: '00',
                    minutes: '0',
                    seconds: '00',
                    delay: false,
                    orderHours: '00',
                    orderMinutes: '0',
                    orderSeconds: '00',
                    orderDelay: false,
                },
                () => {
                    audio.loop = false;
                    audio.pause();
                    audio.currentTime = 0;
                }
            );
        }
    };

    handleClickPickup = () => {
        this.setState({ showConfirmationModal: true });
    };

    handleConfirmPickup = () => {
        const { LiveDispatchStore, route } = this.props;
        this.setState({ loading: true });
        LiveDispatchStore.handleConfirmPickupOrder(route.delivery_id);
        this.setState({ showConfirmationModal: false });
    };

    handleRequestRider = () => {
        this.setState({
            showPartyRiderModal: true,
        });
    };

    handleCloseConfirmationModal = () => {
        this.setState({ showConfirmationModal: false });
    };

    componentWillUnmount() {
        this.resetCountdown();
    }

    sendRoute = () => {
        const { LiveDispatchStore, route } = this.props;

        LiveDispatchStore.sendRouteDispatch(route.id);
        this.setState({ showPartyRiderModal: false });
        setTimeout(() => {
            LiveDispatchStore.handleGetDispatches(true);
        }, 1000);
    };

    render() {
        const {
            hours,
            minutes,
            seconds,
            delay,
            orderHours,
            orderMinutes,
            orderSeconds,
            orderDelay,
            showConfirmationModal,
        } = this.state;
        const { route, LiveDispatchStore } = this.props;
        const { CANCELLED, ON_ROUTE, ROLLING_HYBRID, ALWAYS_AVAILABLE } =
            LiveDispatchStore;

        const onRoute = (bag) =>
            bag.items
                .filter(
                    (item) =>
                        item.stock_type === ROLLING_HYBRID ||
                        item.stock_type === ALWAYS_AVAILABLE
                )
                .every((item) => item.status === ON_ROUTE);
        const allBagsOnRoute = route.bags.every((bag) => onRoute(bag));

        return (
            <Fragment>
                <div
                    className={`${base}__route ${
                        allBagsOnRoute ? 'on_route' : ''
                    } `}
                >
                    {route.isPickUpOrder ? (
                        <Fragment>
                            {this.state.loading ? (
                                <ReactLoading
                                    type="spin"
                                    color="black"
                                    height={'10%'}
                                    width={'10%'}
                                    className="loader"
                                />
                            ) : (
                                <Fragment>
                            <div
                                className={`${base}__route-content-container width--100 height--100`}
                                onClick={route.amount_due ? this.handleClickPickup : this.handleConfirmPickup}
                                style={{cursor: 'pointer'}}
                            >

                                <div
                                    className={`${base}__route-number-and-dispatch display--flex flex-align-items-center`}
                                >
                                    <div
                                        style={{
                                            width: '30px',
                                            height: '30px',
                                        }}
                                    >
                                        <img
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                filter:"invert(1)"
                                            }}
                                            src= "https://image.popcontent.link/takeaway_icon.svg"
                                        />
                                    </div>
                                    <div
                                        className={`${base}__rider width--100`}
                                        style={{paddingLeft: '10px'}}
                                    >
                                        {`${route.bags[0].customer_name ? route.bags[0].customer_name : route.bags[0].order_number}`}
                                        <br />
                                        {`${route.bags[0].phone_number ? route.bags[0].phone_number : ''}`}
                                    </div>
                                </div>
                                <div className='display--flex'>
                                    <div
                                        className={`${base}__route-left-content display--flex`}
                                    >
                                        <div className={`${base}__countdown${
                                                    delay ? '-timer delay' : ''
                                                } pickup mbs mts mlm mrm`}>
                                            <div>
                                                Pickup
                                                {`${delay ? ' ' : ' in '}
                                            ${hours === '0' ? minutes : (Number(hours) * 60 + Number(minutes))}
                                            ${delay ? ' min ago ' : ' min '}`}</div>
                                            <p className="mts text--thin fs--medium text--red">
                                                {route.amount_due
                                                    ? '💵 PAYMENT REQUIRED 💵'
                                                    : ''}
                                            </p>
                                            {route.amount_due ? <div /> :
                                                <p className={`${base}__countdown-note`}>
                                                    Click to mark picked up
                                                </p>}
                                        </div>
                                    </div>
                                    <div
                                        className={`display--flex ps`}
                                        style={{
                                            borderLeft: '3px solid #131415',
                                            width: '100px',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        <div
                                            className={`${base}__route-bags ${
                                                delay ? 'delay' : ''
                                            }`}
                                        >
                                            {route.bags.map((bag) => (
                                                <div
                                                    className={`${base}__bag-info`}
                                                    key={bag.order_number}
                                                >
                                                    <svg className="icon-bag">
                                                        <use
                                                            xlinkHref={`${
                                                                onRoute(bag)
                                                                    ? '#icon-bag-purple'
                                                                    : '#icon-bag'
                                                            }`}
                                                        />
                                                    </svg>
                                                    {bag.status === CANCELLED && (
                                                        <svg className="icon-cancel">
                                                            <use
                                                                xlinkHref={`#icon-cancel`}
                                                            />
                                                        </svg>
                                                    )}
                                                    <span className={`${base}__bag-no`}>
                                                        {bag.bag_number}
                                                    </span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </Fragment>
                        )}
                        </Fragment>
                    ) : (
                        <Fragment>
                            <div
                                className={`${base}__route-content-container width--100 height--100`}
                                style={{display: 'flex',
                                    flexDirection: 'column',
                                    flex: 1
                                }}
                            >
                                <div
                                        className={`${base}__route-number-and-dispatch display--flex flex-align-items-center`}
                                    >
                                        {!route.third_party_delivery_route ? (
                                            !route.third_party_delivery_route &&
                                            !route.driver_alias ? "" : (
                                                <div
                                                    className={`${base}__route-dispatch`}
                                                >
                                                    <div
                                                        className={`${base}__route-no`}
                                                    >
                                                        {route.route_no}
                                                    </div>
                                                    <div
                                                        className={`${base}__dispatch-no`}
                                                >{`${route.dispatch_no ? 'D' + route.dispatch_no : ''}`}</div>
                                                </div>)
                                        ) : (
                                            <div
                                                style={{
                                                    width: '30px',
                                                    height: '30px',
                                                    background: 'white',
                                                }}
                                            >
                                                <img
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                    }}
                                                    src= {route.third_party_delivery_name === "Grab Express" ?  
                                                    "https://image.popcontent.link/GrabFood-WhiteBG.svg" :
                                                    "https://image.popcontent.link/FoodPanda-WhiteBG.svg"}
                                                />
                                            </div>
                                        )}

                                        {route.driver_alias ? (
                                            <div className={`${base}__rider`}>
                                                {route.driver_alias? 
                                                    (route.driver_alias.length > 18 ? 
                                                        route.driver_alias.substring(0, 15) + '...' :
                                                        route.driver_alias) : ""
                                                }
                                                {route.third_party_delivery_route ? (
                                                    <div>
                                                        {route.driver_mobileno}
                                                    </div>
                                                ) : null}
                                            </div>
                                        ) : null}

                                        {!route.driver_alias &&
                                        route.third_party_delivery_route ? (
                                            <div
                                                style={{
                                                    paddingLeft: '10px',
                                                    height: '62.5px',
                                                    fontSize: '1.25rem',
                                                    paddingTop: '18px'
                                                }}
                                            >
                                                3RD-PARTY RIDER REQUESTED
                                            </div>
                                        ) : null}

                                        {!route.third_party_delivery_route &&
                                        !route.driver_alias ? (
                                            <div className={`${base}__no_rider_info`}>
                                                NO RIDER
                                            </div>
                                        ) : null}
                                    </div>

                                    <div
                                        className={`display--flex`}
                                        style={{height: "calc(100% - 62.5px)"}}
                                    >
                                    <div
                                        className={`${base}__route-left-content display--flex flex-align-items-center`}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            flex: 1
                                        }}
                                    >
                                    {route.third_party_delivery_route &&
                                    route.driver_alias ? (
                                        <div
                                            className='mbs mts mlm mrm height--100'>
                                            <div>ESTIMATED PICKUP TIME</div>
                                            <div
                                                className={`${base}__pickupTime`}
                                            >
                                                {moment(
                                                    route.start_date
                                                ).format('hh:mm A')}
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className={`${base}__route-timer display--flex justify--space-between`}
                                            style={{ padding: '5px' }}
                                        >
                                                <div className="display--flex flex-column">
                                                    {(!route.third_party_delivery_route && !route.driver_alias) ?
                                                        <div onClick={this.handleRequestRider} className={`${base}__dispatch-btn`}>
                                                            CLICK TO REQUEST RIDER
                                                        </div> :
                                                        (route.original_order_date ? (
                                                            <div className={`${base}__countdown${orderDelay ? '-timer delay' : ''}`}>
                                                                Pickup
                                                                {`${orderDelay ? '' : ' in '}
                                                                ${hours === '0' ? minutes : (Number(hours) * 60 + Number(minutes))}
                                                                ${orderDelay ? ' min ago ' : ' min '}`}
                                                            </div>
                                                        ) : (
                                                            <div className={`${base}__countdown`}>Pickup</div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className={`display--flex ps`} style={{
                                        borderLeft: '3px solid #131415',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <div
                                     className={`${base}__route-bags ${delay ? 'delay' : ''}`}>
                                        {route.bags.map((bag) => (
                                            <div className={`${base}__bag-info`} key={bag.order_number}>
                                                <svg className="icon-bag">
                                                    <use
                                                        xlinkHref={`${
                                                            onRoute(bag)
                                                                ? '#icon-bag-purple'
                                                                : '#icon-bag'
                                                        }`}
                                                    />
                                                </svg>
                                                {bag.status === CANCELLED && (
                                                    <svg className="icon-cancel">
                                                        <use
                                                            xlinkHref={`#icon-cancel`}
                                                        />
                                                    </svg>
                                                )}
                                                <span
                                                    className={`${base}__bag-no`}
                                                >
                                                    {bag.bag_number}
                                                </span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                </div>
                            </div>
                        </Fragment>
                    )}
                </div>

                {this.state.showPartyRiderModal ? (
                    <Modal width={'900px'} padding={'0px'}>
                        <div
                            style={{
                                textAlign: 'center',
                                padding: '5.125rem 4.625rem',
                            }}
                        >
                            <p
                                style={{
                                    fontSize: '72px',
                                    fontWeight: '700',
                                    color: 'black',
                                }}
                            >
                                Request 3rd-party rider?
                            </p>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    margin: '20px 0px',
                                }}
                            >
                                {route.bags.map((bag) => (
                                    <div key={bag.order_number}>
                                        <span className={`popup-bag`}>
                                            Bag {bag.bag_number}
                                        </span>
                                    </div>
                                ))}
                            </div>

                            <p
                                style={{
                                    fontSize: '2.25rem',
                                    fontFamily: 'Inter',
                                    fontWeight: '400',
                                }}
                            >
                                Only do this if no Pop riders are available
                                anytime soon.
                            </p>
                            <br />
                            <br />
                            <div className={`${base}__confirmation_btns`}>
                                <button
                                    className={`${base}__button cancel`}
                                    onClick={(e) => {
                                        this.setState({
                                            showPartyRiderModal: false,
                                        });
                                    }}
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={(e) => {
                                        this.sendRoute();
                                    }}
                                    className={`${base}__button confirm`}
                                >
                                    Confirm
                                </button>
                            </div>
                        </div>
                    </Modal>
                ) : null}
                {route.isPickUpOrder && showConfirmationModal && (
                    <Modal width="500px">
                        <Fragment>
                            <h1 className="mt0 mbxs">
                                Confirm{' '}
                                <span className="text--red">
                                    {route.amount_due
                                        ? route.pre_tax_net_total
                                        : ''}
                                </span>
                                {`${
                                    route.amount_due ? ' Collected' : ' Pick Up'
                                }`}
                            </h1>
                            <h1 className="mt0 fs--medium-large">{`#${route.order_number}`}</h1>
                            <p className="text--sofiapro">
                                Do you confirm that the right customer has
                                already paid for their meal and verify their
                                purchase?
                            </p>
                            <button
                                className="btn btn-black display--block width--100 mbs"
                                onClick={() => this.handleConfirmPickup()}
                            >
                                Confirm
                            </button>
                            <button
                                className="btn btn-borderless display--block width--100"
                                onClick={this.handleCloseConfirmationModal}
                            >
                                Cancel
                            </button>
                        </Fragment>
                    </Modal>
                )}
            </Fragment>
        );
    }
}

export default Route;
