// App Config for staging profile
export default {
    // Base URL
    baseUrl:
        'http://dahmakan-admin-attendance-staging.s3-website-ap-southeast-1.amazonaws.com/',

    // Hostname for static endpoints
    staticAssets:
        'http://dahmakan-admin-attendance-staging.s3-website-ap-southeast-1.amazonaws.com/',

    // Hostname for API endpoints
    services: 'https://staging-api.dahmakan.com',

    // Webhook for tracking picking
    pickingTracking:
        'https://script.google.com/a/dahmakan.com/macros/s/AKfycbx4BAY_XYGa60qc4GUDgsHKcKEPkL3vrBrntdDa8Q/exec',

    warmerStockPlanner: {
        url:
            'https://a0e4uyk615.execute-api.ap-southeast-1.amazonaws.com/warmer_dev',
        token: 'fLSSAduk5k1PhVf3cNna67PMU8HV0Qvy6TXdesss',
    },

};
