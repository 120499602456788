import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { inject, observer } from 'mobx-react';
import Webcam from 'react-webcam';
import Fullscreen from 'react-full-screen';
import BarcodeReader from 'react-barcode-reader';
import LiveDispatchHeader from './LiveDispatchHeader';
import Route from './Route';
import Loading from '../Loading';
import ScanningBox from '../ScanningBox';
import Modal from '../Modal';
import QrReader from 'react-qr-reader';
import Orders from './Orders';
import moment from 'moment';

const base = 'live-dispatch';
const audio = new Audio(`${process.env.PUBLIC_URL}/alert_store_closed.mp3`);
const greatJobAudio = new Audio(`${process.env.PUBLIC_URL}/great_job.mp3`);

@inject(
    'DispatchStore',
    'LiveDispatchStore',
    'ProductionStore',
    'UserStore',
    'ScanningStore',
    'RiderAndOutletPartnerStore'
)
@observer
class LiveDispatch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isFullscreen: false,
            isFirstMount: true,
            lastEarlyPreparedOrder: null,
            isVisible: false,
            lastScannedItem: null
        };
        this.webcamRef = React.createRef();
        this.interval = null;
        this.storeInterval = null;
        this.gifInterval = null;
    }

    componentDidMount() {
        const {
            LiveDispatchStore: {
                handleGetDispatches,
                handleGetDineInOrders,
                handleGetAggregatorStoreStatus,
            },
            RiderAndOutletPartnerStore: {
                getActiveStaffMembers,
            },
            UserStore: {
                handleSetFullscreen
            },
        } = this.props;
        const { isFirstMount } = this.state;

        if (isFirstMount) {
            handleSetFullscreen(true);
            this.setState({ isFirstMount: false });
        }
        handleGetDispatches(true);
        handleGetDineInOrders(true);
        this.interval = setInterval(() => {
            handleGetDispatches(false);
            handleGetDineInOrders(false);
            // Remove iframe blocking UI
            const el = document.querySelector("body > iframe");
            if (el) {
                el.remove();
            }
        }, 1000 * 10); // 10 seconds

        window.addEventListener('keydown', this.handleScanPopup);

        window.addEventListener('testScanEvent', this.testScanEvent);

        getActiveStaffMembers();
        handleGetAggregatorStoreStatus();
        this.storeInterval = setInterval(() => {
            getActiveStaffMembers();
//            handleGetAggregatorStoreStatus();
        }, 1000 * 60); // 1 minute
    }

    testScanEvent = (event) => {
        this.handleScanWrapper(event.detail.scanData);
    }

    handleScanPopup = () => {
        const { LiveDispatchStore } = this.props;
        LiveDispatchStore.handleScanPopup();
    }

    handleScanWrapper = (scannedBarcode) => {
        if (scannedBarcode == null) {
            return;
        }
        const { LiveDispatchStore, ScanningStore } = this.props;
        const { setItemImage, handledScannedItem, handledScannedBag, handledScannedWarmerItem, displayErrorResultFunc } =
            LiveDispatchStore;
        const { handleScan } = ScanningStore;
        if (this.webcamRef.current) {
            const image = this.webcamRef.current.getScreenshot();
            setItemImage(image);
        }
        let isBagQRCode = scannedBarcode.split('-').length == 1;
        if (isBagQRCode) {
            handledScannedBag(scannedBarcode);
        } else {
            handleScan(scannedBarcode, handledScannedItem, handledScannedWarmerItem, displayErrorResultFunc);
        }
    };

    handleClickOpen = (store) => {
        const { LiveDispatchStore } = this.props;
        LiveDispatchStore.handleOpenStore(store);
    };


    componentDidUpdate() {
        const { LiveDispatchStore: { scannedItem } } = this.props;
        const { lastScannedItem } = this.state;

        if (scannedItem && scannedItem.item && lastScannedItem != scannedItem.item) {
            this.setState({ lastScannedItem: scannedItem.item });
        }
    }

    componentWillUnmount() {
        const {
            LiveDispatchStore: { resetProductionLastModified },
        } = this.props;

        clearInterval(this.interval);
        clearInterval(this.storeInterval);
        clearInterval(this.gifInterval);
        resetProductionLastModified();
    }

    handleError = (err) => {};

    getRouteId = (route) => {
        if (route.delivery_id) {
            return route.delivery_id;
        }
        if (route.dispatch_no && route.route_no) {
            return route.dispatch_no + '-' + route.route_no;
        }
        return route.start_date;
    };

    handleClick =(event) => {
        if (event.target && event.target.className.indexOf("dispatch_scan") == -1) {
            this.handleScanPopup()
        }
    }

    render() {
        const { LiveDispatchStore, UserStore } = this.props;
        const {
            bags,
            routes,
            loading,
            itemImage: image,
            itemScanLoading,
            scannedItem,
            scannedItemSKU,
            scannedItemError,
            showScannedItemOnLiveDispatch,
            originalItemImageLink,
            recentlyClosedAggregatorStores,
            thirdPartyDeliveryErrorMessage,
            orderNumber,
        } = LiveDispatchStore;

        const {
            isCurrentPageFullScreen: isFullscreen,
            handleSetFullscreen,
            handleExitFullscreen
        } = UserStore;

        const { lastScannedItem } = this.state;

        if (recentlyClosedAggregatorStores.length > 0) {
            audio.play();
        }

        if (loading) {
            return <Loading />;
        }

        if (scannedItem && scannedItem.number_of_scanned_items == scannedItem.number_of_items) {
            const now = moment((scannedItem.pick_up_type == "DINE_IN" || scannedItem.pick_up_type == "TAKE_AWAY") ? 
            (scannedItem.production_started_at ? scannedItem.production_started_at : scannedItem.order_date) : scannedItem.production_started_at, 'x');
            const then = moment();
            const duration = moment.duration(then.diff(now)).asMilliseconds();
            const minutes = moment.utc(duration).format('mm');

            if ((this.state.lastEarlyPreparedOrder == null || 
                (scannedItem.item && this.state.lastEarlyPreparedOrder != scannedItem.item.order_number))
                && parseInt(minutes) < 2) {
                greatJobAudio.play();
                this.setState({lastEarlyPreparedOrder : scannedItem.item.order_number, isVisible : true})
                this.gifInterval = setTimeout(() => {
                    this.setState({ isVisible: false });
                  }, 1560);
            }
        }

        return (
            <Fullscreen
                enabled={isFullscreen}
                onChange={(isFullscreen) => {
                    if (isFullscreen) {
                        handleSetFullscreen(isFullscreen);
                    } else {
                        handleExitFullscreen();
                    }
                }}
            >
                <div onClick={this.handleClick}
                    style={isFullscreen ? {height : '100vh', backgroundColor: 'white'} : {height: 'calc(100vh - 75px)', backgroundColor: 'white'}}>
                    <Helmet>
                        <title>Packing | Outlet OS | Pop Meals</title>
                    </Helmet>
                    <LiveDispatchHeader />
                    <div className={`${base}`}>
                        <div className={`${base}__webcam-area`}>
                            webcam picture quality, helpful issue for future ref: https://github.com/mozmorris/react-webcam/issues/195 */}
                                <Webcam
                                audio={false}
                                ref={this.webcamRef}
                                screenshotFormat="image/jpeg"
                                className="webcam"
                                screenshotQuality={1}
                                forceScreenshotSourceSize={true}
                                videoConstraints={{
                                    height: 1080,
                                    width: 1920,
                                }}
                            />
                        </div>
                        {!scannedItem && (
                            <div className={`bag_scan__qr-reader`}>
                                    <div className={`bag_scan__scan-text`}> Scan the bag's QR code </div>
                                    <QrReader
                                        delay={500}
                                        onError={this.handleError}
                                        onScan={this.handleScanWrapper}
                                        facingMode="environment"
                                    />
                            </div>
                        )
                        }
                        {(routes && routes.length > 0) && (
                        <div
                            className={`${base}__route-outer-section display--flex`}
                        >
                            <div
                                className={`${base}__route-section display--flex col-sm-12`}
                            >
                                <div className={`${base}__routes`}>
                                    <div>
                                        <h1
                                            className={`display--inline-block ${base}__heading pll prl ptm pbm`}
                                        >
                                            Pop App Deliveries & Pickups
                                        </h1>
                                    </div>
                                    <div
                                        className={`${base}__routes-container display--flex pll`}
                                    >
                                        {routes.map((route) => (
                                            <Route
                                                route={route}
                                                key={this.getRouteId(route)}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        )}

                        <div className={`display--flex bg--white col-sm-12 height--100 p0`}>
                                <div
                                    className={`${base}__dine-in-orders-container`}
                                    style={{
                                        paddingRight: '10px',
                                        borderRight: '3px solid #e5e5e5',
                                        overflow: 'auto'
                                    }}
                                >
                                    <Orders 
                                    inOutletOrders = {true}
                                    routeSectionVisible = {routes && routes.length > 0}
                                    lastScannedItem = {lastScannedItem} />
                                </div>
                                <div
                                    className={`${base}__dine-in-orders-container`}
                                >
                                    <Orders inOutletOrders = {false}
                                    routeSectionVisible = {routes && routes.length > 0}
                                    lastScannedItem = {lastScannedItem}/>
                                </div>
                        </div>
                    </div>
                    <BarcodeReader
                        onError={this.handleError}
                        onScan={this.handleScanWrapper}
                    />
                    <ScanningBox
                        show={showScannedItemOnLiveDispatch}
                        scannedItem={scannedItem}
                        scannedItemSKU={scannedItemSKU}
                        scannedItemError={scannedItemError}
                        handleScan={this.handleScanWrapper}
                        loading={itemScanLoading}
                        originalItemImageLink={originalItemImageLink}
                        itemImage={image}
                        isCurrentPageFullScreen={isFullscreen}
                    />
                    {this.state.isVisible && (
                        <img src='https://image.popcontent.link/great_job.gif' 
                        className={isFullscreen ? `${base}__great_job_full_screen_img` : `${base}__great_job_img`}/>
                    )}

                {thirdPartyDeliveryErrorMessage ? (
                        <Modal width={'900px'} padding={'0px'}>
                            <div
                                style={{
                                    textAlign: 'center',
                                    padding: '5.125rem 4.625rem',
                                }}
                            >
                                <p
                                    style={{
                                        fontSize: '72px',
                                        fontWeight: '700',
                                        color: 'rgba(251, 39, 95, 1)',
                                    }}
                                >
                                    3rd-party rider request rejected
                                </p>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        flexDirection: 'column',
                                        margin: '20px 0px',
                                    }}
                                >
                                    {bags.map((bag) => {
                                        if (
                                            orderNumber.includes(bag.order_number)
                                        ) {
                                            return (
                                                <div key={bag.order_number}>
                                                    <span className={`popup-bag`}>
                                                        Bag {bag.bag_number}
                                                    </span>
                                                </div>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })}
                                </div>

                                <p
                                    style={{
                                        fontSize: '2.25rem',
                                        fontFamily: 'Inter',
                                        fontWeight: '400',
                                    }}
                                >
                                Third Party is unable to accept the request
                                    <br />
                                    Please try to find a Pop rider
                                </p>
                                <br />
                                <br />
                                <div className={`${base}__confirmation_btns`}>
                                    <button
                                        style={{ backgroundColor: 'black' }}
                                        className={`${base}__button`}
                                        onClick={(e) => {
                                        LiveDispatchStore.thirdPartyDeliveryErrorMessage = false;
                                        }}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </Modal>
                    ) : null}

                    {recentlyClosedAggregatorStores.length > 0 && (
                        <Modal width={'949px'}>
                            <div style={{ textAlign: 'center' }}>
                                <p
                                    style={{
                                        fontSize: '72px',
                                        fontWeight: '700',
                                        color: '#FB275F',
                                    }}
                                >
                                    Alert - Store Closed
                                </p>
                                <br />
                                <br />
                                <br />
                                <div
                                    style={{ fontSize: '36px', fontWeight: '700' }}
                                >
                                    {recentlyClosedAggregatorStores.map((store) => (
                                        <div className="row" key={store}>
                                            <div className="col-xs-8">{store}</div>
                                            <div className="col-xs-4">
                                                <button
                                                    className="btn btn-primary width--150px text--bold"
                                                    onClick={() =>
                                                        this.handleClickOpen(store)
                                                    }
                                                >
                                                    Open Now
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <br />
                                <br />
                                <br />
                                <p style={{ fontSize: '30px', fontWeight: '400' }}>
                                    These stores are closed. Please re-open them
                                    immediately
                                </p>
                                <br />
                                <br />
                                <div className={`${base}__confirmation_btns`}>
                                    <button
                                        className="btn btn-secondary ms flex-1"
                                        onClick={() => {
                                            LiveDispatchStore.recentlyClosedAggregatorStores =
                                                [];
                                            audio.pause();
                                        }}
                                        style={{
                                            backgroundColor: 'black',
                                            width: '200px',
                                            height: '70px',
                                            fontSize: '24px',
                                        }}
                                    >
                                        OK
                                    </button>
                                </div>
                            </div>
                        </Modal>
                    )}
                </div>
            </Fullscreen>
        );
    }
}

export default LiveDispatch;
